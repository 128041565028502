<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<number-bars class="number-bars--green" :title="`Total Volume of Securities Transactions Settled`" unit="( in millions )" :numbers="totalVolumeSecurities" />

			<number-bars class="number-bars--green" :title="`Total \nValue of Securities Settled`" unit="( in trillions )" :numbers="totalValueSecurities" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'SettlementAsset',
	components: {
		NumberBars,
	},
	data: () => ({
		totalValueSecurities: [
			{
				label: '2020',
				value: 131.3,
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				label: '2019',
				value: 120.8,
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				label: '2018',
				value: 122.6,
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
		],
		totalVolumeSecurities: [
			{
				label: '2020',
				value: 561,
				unit: 'Million',
			},
			{
				label: '2019',
				value: 654,
				unit: 'Million',
			},
			{
				label: '2018',
				value: 389,
				unit: 'Million',
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
